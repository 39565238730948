import Mock from 'mockjs'

const meta = Mock.mock({
  updateSource: Mock.Random.upper(Mock.Random.word(3, 5)) + '阿基米德资管一体化平台',
  updateDate: Mock.Random.date(),
  items: [
    { title: '当前交易情绪', 'value|1-10.1-1': 1, unit: '', 'trend|0-1.1-2': 1, 'icon|1': ['up', 'down'] },
    { title: '当前中国版VIX指数', 'value|1-10.1-1': 1, unit: '', 'trend|0-1.1-2': 1, 'icon|1': ['up', 'down'] },
    { title: '当前股债性价比', 'value|1-10.1-1': 1, unit: '%', 'trend|0-100': 1, 'icon|1': ['up', 'down'] },
    { title: '当前交易情绪', 'value|1-10.1-1': 1, unit: '', 'trend|0-1.1-2': 1, 'icon|1': ['up', 'down'] },
    { title: '当前交易情绪', 'value|1-10.1-1': 1, unit: '', 'trend|0-1.1-2': 1, 'icon|1': ['up', 'down'] }
  ]
})

export { meta }
