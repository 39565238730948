const windowWidth = 375

let scale = 1

const watchScale = function (app) {
  const baseSize = 16 // 32
  function setRem () {
    scale = document.documentElement.clientWidth / windowWidth
    document.documentElement.style.fontSize = baseSize * scale + 'px'
  }

  window.onresize = function () {
    setRem()
  }
  setRem()
  return app
}

const install = (app) => {
  watchScale(app)
}
export default { install }

export function px2rem (res, suffix = null) {
  if (!suffix) {
    return res * scale
  } else {
    return res * scale + suffix
  }
}
