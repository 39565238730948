<template>
  <div class="uz-container">
    <img class="uz-top-img" src="~@/assets/imgs/bg_1@2x.png" />
    <div class="uz-form" v-if="!showResult">
      <div class="uz-f-input" @click.stop.prevent="selectionEnd('ageInput')">
        <div class="uz-f-input-label">
          年龄
        </div>
        <div class="uz-f-input-suffix" @click.stop.prevent="none">
          <input type="number" pattern="[0-9]*" ref="ageInput" placeholder="" maxlength="3" class="uz-f-input-text" @input="validatorAge" @focus="focusInput" v-model="age" />
          <div class="uz-f-input-icon">
            <img class="uz-icon" src="~@/assets/imgs/icon_edit@2x.png" />
          </div>
        </div>
      </div>
      <div class="uz-f-input">
        <div class="uz-f-input-label">
          性别
        </div>
        <div class="uz-f-input-suffix" @click.stop.prevent="none">
          <SelectPicker :options="genderArray" :value="gender" @change="(item) => gender = item" />
        </div>
      </div>
      <div class="uz-f-input">
        <div class="uz-f-input-label">
          社保缴纳省份
        </div>
        <div class="uz-f-input-suffix" @click.stop.prevent="none">
          <SelectPicker :options="sbjnbArray" :value="sbjnbl" @change="(item) => sbjnbl = item" />
        </div>
      </div>
      <div class="uz-f-input" @click="selectionEnd('gjj')">
        <div class="uz-f-input-label">
          公积金缴纳比例
        </div>
        <div class="uz-f-input-suffix" @click.stop.prevent="none">
          <input type="number" pattern="[0-9]*" ref="gjj" maxlength="4" placeholder="" class="uz-f-input-text" @input="validatorGjj" @focus="focusInput" v-model="gjj" />
          <div class="uz-f-input-icon">
            <div class="uz-unit">%</div>
            <img class="uz-icon" src="~@/assets/imgs/icon_edit@2x.png" />
          </div>
        </div>
      </div>
      <div class="uz-f-input" @click="selectionEnd('annualIncomeInput')">
        <div class="uz-f-input-label">
          年收入
          <span>(税前含奖金)</span>
        </div>
        <div class="uz-f-input-suffix" @click.stop.prevent="none">
          <input type="number" pattern="[0-9]*" ref="annualIncomeInput" maxlength="4" placeholder="" class="uz-f-input-text" @input="validatorAnnualIncome" @focus="focusInput" v-model="annualIncome" />
          <div class="uz-f-input-icon">
            <div class="uz-unit">万</div>
            <img class="uz-icon" src="~@/assets/imgs/icon_edit@2x.png" />
          </div>
        </div>
      </div>
      <div class="uz-vas">
        <div class="uz-switch-box">
          <div class="uz-text">是否有其他专项附加扣除？</div>
          <div class="uz-switch" @click="switchVas" :class="enableVas ? 'uz-checked' : ''">
            <div class="uz-icon"></div>
          </div>
        </div>
        <div v-if="enableVas" class="uz-vas-list">
          <template v-for="(item, i) in vas" :key="i">
            <div class="uz-item" :class="disableItem(item)" @click="checkedVas(item)">
              <img class="uz-icon" :src="iconSrc(item)" />
              <div class="uz-text">{{ itemTitle(item) }} <span class="uz-tip">(偿还房贷和租房只能抵扣一项)</span></div>
            </div>
            <div class="uz-i-input" v-if="item.value && item.extInput.enable">
              <div class="uz-f-input" v-if="item.extInput.enable" @click="selectionEnd(item.name)">
                <div class="uz-f-input-label">
                  {{ item.extInput.label }}
                </div>
                <div class="uz-f-input-suffix" @click.stop.prevent="none">
                  <input type="number" pattern="[0-9]*" :ref="item.name" maxlength="2" class="uz-f-input-text" @input="validatorChildrenNumber" @focus="focusInput" v-model="item.extInput.value" />
                  <div class="uz-f-input-icon">
                    <div class="uz-unit">{{ item.extInput.unit }}</div>
                    <img class="uz-icon" src="~@/assets/imgs/icon_edit@2x.png"  />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="uz-vas-result" style="display: none" v-if="showResult">
        <div class="uz-title">其他专项附加扣除</div>
        <div class="uz-vas-list">
          <template v-for="(item, i) in checked" :key="i">
            <div class="uz-item">
              <div class="uz-icon"></div>
              <div class="uz-text">{{ item.text }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="uz-panel uz-form" v-if="showResult && result.ybzsdss > 0">
      <div class="uz-t-bg">
        <img class="uz-t-bg-img" src="~@/assets/imgs/bg_light@2x.png" />
        <img class="uz-t-bg-title" src="~@/assets/imgs/result-title@2x.png" />
      </div>
      <div class="uz-items">
        <div class="uz-item">
          <div class="uz-label">养老账户年度缴纳</div>
          <div class="uz-value">
            <div class="uz-num">{{ ylDisplay() }}</div>
            元
          </div>
        </div>
        <div class="uz-item">
          <div class="uz-label">每年约可减税</div>
          <div class="uz-value">
            <div class="uz-num">{{ formatPrice(result.mnsdss) }}</div>
            元
          </div>
        </div>
      </div>
      <div class="uz-v-result">
        <div class="uz-text">个人养老金账户退休时总减税额</div>
        <div class="uz-value">
          <div class="uz-num">{{ formatWPrice(result.ybzsdss) }}</div>
          万元
        </div>
      </div>
    </div>
    <div class="uz-panel uz-form" v-if="showResult && result.ybzsdss <= 0">
      <div class="uz-t-bg">
        <img class="uz-t-bg-img" src="~@/assets/imgs/bg_light@2x.png" />
        <img class="uz-t-bg-title" src="~@/assets/imgs/result-title@2x.png" />
      </div>
      <div class="uz-v-result uz-v-result-empty">
        <div class="uz-text">您目前已享受最低税率</div>
        <div class="uz-text">暂不享受养老税收优惠</div>
      </div>
    </div>
    <img class="uz-banner" v-if="showResult" src="~@/assets/imgs/banner@2x.png" />

    <div class="uz-footer">
      <div class="uz-btn" @click="calc" v-if="!showResult">开始计算</div>
      <div class="uz-btn" @click="reset" v-if="showResult">重新计算</div>
    </div>
    <p class="uz-tip" :class="showResult ? '' : 'uz-clean'">
      风险提示：<br>
      本计算器由上海华夏财富投资管理有限公司提供，税务计算依据国家《个人所得税累进税率表》、《个人所得税专项附加扣除暂行办法》。税务计算中以全国各省省会城市2022年公示的相关社保扣除政策进行试算，计算结果仅供参考，实际缴费应以当前社保缴纳所在地为准。专项附加扣除计算中（1）并未引入大病医疗扣除项（2）子女教育、3岁以下婴幼儿照护及赡养老人等附加扣除默认全额扣除至计算器使用者本人扣除项中（3）住房租金以北京市租金扣除金额为例。退休时总减税金额以男60女55岁退休为例进行计算，因收入变化、提前或延后退休等原因或许导致计算结果与真实税收减免金额不一致。关于个人养老金实行个人所得税优惠的相关计算参考《国务院办公厅关于推动个人养老金发展的意见》中相关内容，不代表个人养老金个人所得税优惠的最终计算方式，仅供参考。具体计算方式应以相关部门最终颁布的执行方案进行计算。
    </p>
  </div>
</template>

<script>
import { vas } from '../config/vas'
import { config, calculator, gender as genderArray, sbjnbl as sbjnblOrignal } from '../utils/yuanglao'
import { numberFormat } from '../utils/index'
import SelectPicker from '../components/select'
const sbjnbArray = sbjnblOrignal.map((item) => {
  item.value = item.name
  return item
})
export default {
  components: {
    SelectPicker
  },
  data () {
    return {
      dtyl: config.dtyl,
      age: 30,
      annualIncome: 30,
      gender: genderArray[0],
      sbjnbl: sbjnbArray[0],
      gjj: 10,
      enableVas: false,
      vas: Object.assign([], vas),
      showResult: false,
      checked: [],
      result: {},
      genderArray,
      sbjnbArray
    }
  },
  methods: {
    none () {
    },
    validatorAge (e) {
      const val = e.target.value
      if (/^(\d){1,3}$/.test(val)) {
        const age = parseInt(val)
        if (age < 16) {
          // this.age = 16
        } else if (age > 59) {
          this.age = 59
        }
      } else {
        let text = val.replace(/[^0-9]/g, '')
        if (text.length > 3) {
          text = text.slice(0, 3)
        }
        this.age = text
        // this.age = 30
      }
    },
    validatorAnnualIncome (e) {
      const val = e.target.value
      if (/^(\d){1,3}$/.test(val)) {
        const price = parseInt(val)
        if (price > 1000) {
          this.annualIncome = 1000
        }
      } else {
        let text = val.replace(/[^0-9]/g, '')
        if (text.length > 4) {
          text = text.slice(0, 4)
        }
        this.annualIncome = text
        // this.annualIncome = 30
      }
    },
    validatorGjj (e) {
      const val = e.target.value
      if (/^(\d){1,2}$/.test(val)) {
        const number = parseInt(val)
        if (number > 12) {
          this.gjj = 12
        }
      } else {
        let text = val.replace(/[^0-9]/g, '')
        if (text.length > 2) {
          text = text.slice(0, 2)
        }
        this.gjj = text
        // this.annualIncome = 30
      }
    },
    validatorChildrenNumber (e) {
      const val = e.target.value
      if (/^(\d){1,2}$/.test(val)) {
        const num = parseInt(val)
        if (num > 10) {
          e.target.value = 10
        }
      } else {
        let text = val.replace(/[^0-9]/g, '')
        if (text.length > 2) {
          text = text.slice(0, 2)
        }
        e.target.value = text
        // this.annualIncome = 30
      }
    },
    focusInput (e) {
      const val = e.target.value
      e.target.value = ''
      setTimeout(() => {
        e.target.value = val
      }, 10)
    },
    selectionEnd (t) {
      if (this.$refs[t] instanceof Array) {
        this.$refs[t][0].focus()
      } else {
        this.$refs[t]?.focus()
      }
    },
    switchVas () {
      this.enableVas = !this.enableVas
      if (!this.enableVas) {
        this.vas.map((obj) => {
          obj.value = false
        })
      }
      if (this.enableVas && this.showResult) {
        this.showResult = false
      }
      this.checkSelected()
    },
    checkedVas (item) {
      item.value = !item.value
      if (item.name === 'houseLoan4one') {
        this.vas.forEach(v => {
          if (v.name === 'rentingHouse') {
            v.disabled = item.value
            if (item.value) {
              v.value = null
            }
          } else {
            v.disabled = false
          }
        })
      } else if (item.name === 'rentingHouse') {
        this.vas.forEach(v => {
          if (v.name === 'houseLoan4one') {
            v.disabled = item.value
            if (item.value) {
              v.value = null
            }
          } else {
            v.disabled = false
          }
        })
      }
      this.checkSelected()
    },
    checkSelected () {
      this.checked = this.vas.filter(obj => obj.value)
    },
    calc () {
      if (this.age > 59) {
        alert('年龄不能超过59岁')
        return
      }
      if (this.age < 16) {
        alert('年龄不能小于16岁')
        return
      }
      if (this.annualIncome <= 0) {
        alert('年收入不能低于0万')
        return
      }
      this.showResult = true
      if (this.checked.length < 1) {
        this.enableVas = false
      }

      // gender: genderArray[0],
      //   sbjnbl: sbjnbArray[0],
      //   gjj: 10,
      this.result = calculator.calc(this.age, this.annualIncome * 10000, this.gender, this.sbjnbl, this.gjj / 100, this.checked)
    },
    reset () {
      this.showResult = false
    }
  },
  computed: {
    iconSrc () {
      return (item) => {
        const checked = item.value ? 'icon-checked@2x.png' : 'icon-uncheck@2x.png'
        const img = require(`@/assets/imgs/${checked}`)
        return img
      }
    },
    itemTitle () {
      return (item) => {
        return item.text
      }
    },
    disableItem () {
      return (item) => {
        return item.disabled ? 'uz-disabled' : ''
      }
    },
    ylDisplay () {
      return () => {
        return numberFormat(calculator.getYl(true), 0)
      }
    },
    formatPrice () {
      return (price) => {
        return numberFormat(price)
      }
    },
    formatWPrice () {
      return (price) => {
        const wprice = price / 10000
        return numberFormat(wprice, 1)
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="less" scoped>
  .uz-container {
    width: 100%;
    overflow: hidden;
    height: auto;
    > .uz-top-img {
      display: block;
      width: 100%;
    }
    .uz-form {
      position: relative;
      z-index: 10;
      margin: -61px auto 0;
      width: 345px;
      background: #FFFFFF;
      padding: 16px;
      border-radius: 12px;
      .uz-f-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 18px;
        width: 100%;
        height: 44px;
        border-radius: 8px;
        border: 1px solid #E4E4E4;
        margin-bottom: 12px;
        &-label {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          display: flex;
          align-items: center;
          .uz-icon {
            display: block;
            width: 12px;
            margin-left: 10px;
          }
          span {
            color: #999999;
          }
        }
        /*select {*/
        /*  padding-right: 5px;*/
        /*  background-color: transparent;*/
        /*  text-align: right;*/
        /*  option {*/
        /*    text-align: right;*/
        /*    font-size: 16px;*/
        /*    font-family: DINOT-Bold, DINOT;*/
        /*    font-weight: bold;*/
        /*    color: #333333;*/
        /*  }*/
        /*}*/
        &-text {
          display: block;
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          text-align: right;

          font-size: 16px;
          font-family: DINOT-Bold, DINOT;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &:active,&:focus {
            border: none;
          }
        }
        &-suffix {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 40%;
          height: 100%;
        }
        &-icon {
          display: flex;
          align-items: center;
          .uz-unit {
            margin-left: 3px;
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
          }
          img {
            display: block;
            margin-left: 10px;
            width: 12px;
            height: 12px;
          }
        }
      }

      .uz-switch {
        width: 41px;
        height: 24px;
        background: #E7E7E7;
        border-radius: 14px;
        padding: 2px;
        /*display: flex;*/
        /*justify-content: flex-start;*/
        position: relative;
        transition: all .3s;
        .uz-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 2px;
          z-index: 1;
          border-radius: 50%;
          background: #FFFFFF;
          transition: all .3s;
        }
        &.uz-checked {
          /*justify-content: flex-end;*/
          background: #0C48D1;
          .uz-icon {
            left: 19px;
          }
        }
      }

      .uz-vas {
        margin-top: 18px;
        .uz-switch-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .uz-text {
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 21px;
          }
        }
        .uz-vas-list {
          margin-top: 2px;
          .uz-item {
            display: flex;
            align-items: center;
            margin-top: 12px;
            .uz-icon {
              display: block;
              width: 14px;
              height: 14px;
              margin-right: 8px;
              transition: all 3s ease-in-out;
            }
            .uz-text {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              .uz-tip {
                display: none;
              }
            }
            &.uz-disabled {
              .uz-text {
                .uz-tip {
                  display: inline;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
          }
          .uz-i-input {
            padding-left: 22px;
            .uz-f-input {
              margin-top: 10px;
              margin-bottom: 0;
              &-suffix {
                width: 50%;
              }
            }
          }
        }
      }
      .uz-vas-result  {
        padding-top: 8px;
        > .uz-title {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
        .uz-vas-list {
          margin-top: 8px;
          .uz-item {
            display: flex;
            align-items: center;
            .uz-icon {
              width: 6px;
              height: 6px;
              background: #0C48D1;
              margin-right: 8px;
              border-radius: 50%;
            }
            .uz-text {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 32px;
            }
          }
        }
      }
    }
    .uz-panel {
      padding: 59px 16px 16px;
      width: 345px;
      /*height: 244px;*/
      margin: -61px auto 0;
      background: #FFFFFF;
      border-radius: 12px;
      position: relative;
      > .uz-f-input {
      }
      .uz-t-bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: -1;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        &-img {
          position: absolute;
          left: 0;
          top: 1px;
          right: 0;
          width: 100%;
          height: 100%;
        }
        &-title {
          position: relative;
          margin-top: 15px;
          width: 176px;
          height: 31px;
          z-index: 1;
        }
      }
      .uz-items {
        /*position: absolute;*/
        /*top: 60px;*/
        /*left: 0;*/
        /*z-index: 10;*/
        width: 100%;
        padding: 8px 0 0;
        .uz-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .uz-label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
          .uz-value {
            display: flex;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            .uz-num {
              font-size: 16px;
              font-family: DINOT-Bold, DINOT;
              font-weight: bold;
              color: #FF391D;
              line-height: 21px;
              margin-right: 14px;
            }
          }
        }
      }
      .uz-v-result {
        /*position: absolute;*/
        /*left: 16px;*/
        /*right: 0;*/
        bottom: 16px;
        width: 313px;
        height: 105px;
        background: #FFF0EA;
        border-radius: 8px;
        text-align: center;
        padding-top: 20px;
        &.uz-v-result-empty {
          padding-top: 29px;
          .uz-text {
            line-height: 24px;
          }
        }
        .uz-text {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
        }
        .uz-value {
          display: flex;
          justify-content: center;
          align-items: baseline;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FF391D;
          line-height: 25px;
          margin-top: 12px;
          .uz-num {
            font-size: 32px;
            font-family: DINOT-Bold, DINOT;
            font-weight: bold;
            color: #FF391D;
            line-height: 41px;
            margin-right: 3px;
          }
        }
      }
    }
    .uz-banner {
      margin: 10px auto 0;
      display: flex;
      width: 345px;
      height: 101px;
    }
    > .uz-tip {
      margin: 24px auto 0;
      width: 345px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      &.uz-clean {
        margin-top: 85px;
      }
    }
    .uz-footer {
      width: 100%;
      height: 90px;
      margin-top: 20px;

      display: flex;
      justify-content: center;
      padding-top: 10px;

      .uz-btn {
        width: 246px;
        height: 46px;
        background: #0C48D1;
        border-radius: 23px;
        display: flex;
        align-items: center;
        justify-content: center;

        user-select: none;

        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        transition: all 0.3s;
        &:hover,&:active {
          background: rgba(12, 72, 209, 0.73);
        }
      }
    }
  }
</style>
