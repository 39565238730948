import Mock from 'mockjs'
const Random = Mock.Random

const map = [
  { mod: 'overview', text: '顶部预览内容' },
  { mod: 'modular-2', text: '股债性价比' },
  { mod: 'modular-2', text: '股债性价比2' }
]
const modules = Mock.mock(
  Random.pick(map, 1, 3)
)
export { modules }
