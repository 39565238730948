import Mock from 'mockjs'
const map = [
  { id: 'hs300', text: '恒生300' },
  { id: 'hs400', text: '恒生400' },
  { id: 'hs500', text: '恒生500' },
  { id: 'hs600', text: '恒生600' },
  { id: 'sz00', text: '深证100' }
]
const zhishu = Mock.mock(Mock.Random.pick(map, 2, 4))

export { zhishu, map }
