import axios from 'axios'
import { _t } from '../utils'

const baseUrl = process.env.VUE_APP_API_BASE_URL
const http = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  },
  timeout: 5000
})

http.interceptors.request.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})

http.interceptors.response.use(res => {
  const statusCode = `${res.data.code}`
  if (statusCode === '200') {
    return Promise.resolve(res.data)
  } else {
    if (res.data.msg) {
      _t.info(res.data.msg)
    } else if (res.data.message) {
      _t.info(res.data.message)
    }
  }
  return Promise.reject(res)
}, err => {
  console.error(err)
  return Promise.reject(err)
})

const install = function (app) {
  app.config.globalProperties.$http = http
  return app
}
export { install, http }
