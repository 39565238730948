import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../pages/Index'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      title: process.env.VUE_APP_TITLE
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  if (!to.meta) {
    to.meta = { index: 0 }
  }
  if (!from.meta) {
    from.meta = { index: 0 }
  }
  to.meta.transition = from.meta.index > to.meta.index ? 'slide-right' : 'slide-left'
})

router.afterEach((to, from) => {
  // 设置title
  document.title = to.meta.title
})

export default router
