const vas = [
  {
    text: '我有超过60岁的父母需要赡养',
    name: 'provideParents',
    value: null,
    extInput: {
      enable: false,
      value: null
    }
  },
  {
    text: '我有3岁以上至博士在接受教育的子女',
    name: 'childrenEducating',
    value: null,
    extInput: {
      enable: true,
      value: 1,
      label: '子女个数',
      unit: '个'
    }
  },
  {
    text: '我有3岁以下婴幼儿需要照护',
    name: 'takeCareInfant',
    value: null,
    extInput: {
      enable: true,
      value: 1,
      label: '子女个数',
      unit: '个'
    }
  },
  {
    text: '我自己正在接受继续教育',
    name: 'educating',
    value: null,
    extInput: {
      enable: false,
      value: null
    }
  },
  {
    text: '我正在为第一套房偿还房贷',
    name: 'houseLoan4one',
    value: null,
    extInput: {
      enable: false,
      value: null
    }
  },
  // {
  //   text: '大病医疗',
  //   name: 'seriousIllnessTreatment',
  //   value: null,
  //   extInput: {
  //     enable: false,
  //     value: null
  //   }
  // },
  {
    text: '我在工作城市租房',
    name: 'rentingHouse',
    value: null,
    extInput: {
      enable: false,
      value: null,
      label: '每月租金',
      unit: '元'
    }
  }
]

export { vas }
