<template>
  <div ref="selectPicker" class="uz-f-select" @click.stop.prevent="dropdown">
    <div class="uz-f-select-label">
      <div class="uz-f-select-label-text">{{ value?.name }}</div>
      <img class="uz-f-select-label-icon" src="~@/assets/imgs/down_arrow.svg" />
    </div>
    <div class="uz-f-select-dropdown" :class="{'uz-f-select-dropdown-active': active}">
      <template v-for="(item, i) in options" :key="i">
        <div class="uz-f-select-dropdown-item" @click.stop.prevent="select(item)">{{ item.name }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['change'],
  data () {
    return {
      active: false
    }
  },
  methods: {
    dropdown () {
      this.active = true
    },
    select (item) {
      this.$emit('change', item)
      this.active = false
    }
  },
  mounted () {
    document.addEventListener('click', (e) => {
      if (this.$refs.selectPicker && !this.$refs.selectPicker.contains(e.target)) this.active = false
    })
  },
  computed: {
  }
}
</script>
<style lang="less" scoped>
  .uz-f-select {
    width: 100%;
    position: relative;
    text-align: right;
    &-label {
      display: flex;
      align-items: center;
      justify-content:  flex-end;
      &-text {
      }
      &-icon {
        display: block;
        width: 12px;
        margin-left: 10px;
      }
    }
    &-dropdown {
      position: absolute;
      width: 100%;
      display: none;
      max-height: 180px;
      overflow-y: auto;
      transition: all 0.5s;
      z-index: 10;
      top: 30px;
      right: -20px;
      background-color: #F5F5F5;
      border: 1px solid #E4E4E4;
      border-radius: 4px;
      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 60%;
        top: -10px;
        z-index: 20;
        width: 10px;
        height: 10px;
        border: 4px solid transparent;
        border-bottom-color: #F5F5F5;
      }
      &-active {
        display: block;
      }
      &-item {
        padding: 6px 10px;
        border-bottom: 1px solid #E4E4E4;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
        color: #333333;
        line-height: 21px;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
</style>
