import Mock from 'mockjs'
import { map } from './zhishu'
import { lastMonths } from '../../utils'

const Random = Mock.Random
export default {
  mock (filter) {
    let zhishu = null
    map.map((v) => {
      if (v.id === filter.code) {
        zhishu = v
      }
    })
    const legends = ['股债性价比 (口径1)', '+1倍标准差', '-1倍标准差', '+2倍标准差', '-2倍标准差', '滚动3年均值']
    return Mock.mock({
      filter,
      aggs: [
        { tips: `当前${zhishu ? zhishu.text : '3000'}指数`, 'value|3000-4000.2': 1, 'color|1': ['blue', 'red'] },
        { tips: '当前股债性价比', value: Random.float(1, 10, 2, 2) + '%', 'color|1': ['blue', 'red'] },
        { tips: '当前股债性价比中位数水平', value: Random.float(40, 80, 2, 2) + '%', 'color|1': ['blue', 'red'] }
      ],
      charts: {
        legends,
        xAxis: lastMonths(21),
        series: legends.map((v) => {
          return {
            name: v,
            yAxisIndex: 0,
            data: Random.range(0, 21).map(() => {
              return Random.integer(200, 1000)
            })
          }
        })
      }
    })
  }
}
