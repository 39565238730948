import { data as sbjnbl } from './sbjnbl'

const specialExpenseDeductions = {
  provideParents: {
    month: 2000,
    year: 24000
  },
  childrenEducating: {
    month: 2000,
    year: 24000
  },
  takeCareInfant: {
    month: 2000,
    year: 24000
  },
  educating: {
    month: 400,
    year: 4800
  },
  houseLoan4one: {
    month: 1000,
    year: 12000
  },
  rentingHouse: {
    month: 1500,
    year: 18000
  }
}

const config = {
  dtyl: 1000,
  dtylRatio: 0.03,
  msje: 60000,
  maxSxyj: 80911
}

const calculator = (() => {
  return {
    /**
     * 获取全年应纳税所得相关信息
     */
    getQNYNSSDE (annualIncome) {
      if (annualIncome <= 36000) {
        return { ratio: 0.03, sskcs: 0 }
      } else if (annualIncome > 36000 && annualIncome <= 144000) {
        return { ratio: 0.1, sskcs: 2520 }
      } else if (annualIncome > 144000 && annualIncome <= 300000) {
        return { ratio: 0.2, sskcs: 16920 }
      } else if (annualIncome > 300000 && annualIncome <= 420000) {
        return { ratio: 0.25, sskcs: 31920 }
      } else if (annualIncome > 420000 && annualIncome <= 660000) {
        return { ratio: 0.3, sskcs: 52920 }
      } else if (annualIncome > 660000 && annualIncome <= 960000) {
        return { ratio: 0.35, sskcs: 85920 }
      } else if (annualIncome > 960000) {
        return { ratio: 0.45, sskcs: 181920 }
      }
    },
    getSxyj (annualIncome, sbjnbl) { // 三险一金
      // let total = annualIncome * 0.222
      // if (total > config.maxSxyj) {
      //   total = config.maxSxyj
      // }
      // return total

      const sx = sbjnbl.jssx * 12 // 上限
      const ratio = (sbjnbl.ylGr + sbjnbl.ylxGr + sbjnbl.syGr + sbjnbl.sybxblGr + sbjnbl.gsbxblGr)
      if (annualIncome > sx) {
        return sx * ratio
      } else {
        return annualIncome * ratio
      }
    },
    getZxkc (checked) { // 专项扣除
      let total = 0
      if (checked && checked.length > 0) {
        checked.forEach(item => {
          let price = specialExpenseDeductions[item.name].year
          if (item.name === 'childrenEducating' || item.name === 'takeCareInfant') {
            if (item.extInput.value && item.extInput.value > 0 && item.extInput.value < 10) {
              price = price * item.extInput.value
            }
          } else {
          }
          total += price
        })
      }
      return total
    },
    getYl (isyl) { // 养老年度缴额
      let total = 0
      if (isyl) {
        total = config.dtyl * 12
      }
      return total
    },
    getYlSL (isyl) { // 增加养老
      let total = 0
      if (isyl) {
        const ndylje = this.getYl(isyl)
        total = ndylje * config.dtylRatio
      }
      return total
    },
    getGjj (annualIncome, sbjnbl, gjjRatio) {
      const sx = sbjnbl.jssx * 12 // 上限
      if (annualIncome > sx) {
        return sx * gjjRatio
      } else {
        return annualIncome * gjjRatio
      }
    },
    calcItem (annualIncome, gender, sbjnbl, gjj, checked, isyl = false) {
      // 应纳税所得额
      let ynssde = annualIncome - config.msje - this.getZxkc(checked) - this.getSxyj(annualIncome, sbjnbl) - this.getYl(isyl) - this.getGjj(annualIncome, sbjnbl, gjj)
      const gsxx = this.getQNYNSSDE(ynssde)

      ynssde = ynssde < 0 ? 0 : ynssde // 修复与Excel保持一致

      // 应纳个人所得税税额
      const yngrsdse = ynssde * gsxx.ratio - gsxx.sskcs + this.getYlSL(isyl)
      console.log(ynssde, ynssde * gsxx.ratio - gsxx.sskcs, this.getYlSL(isyl))
      return {
        ynssde,
        gsxx,
        yngrsdse
      }
    },
    getMaxAge (gender) {
      return gender.value === 'male' ? 60 : 55
    },
    calc (age, annualIncome, gender, sbjnbl, gjj, checked) {
      const res1 = this.calcItem(annualIncome, gender, sbjnbl, gjj, checked, false)
      const res2 = this.calcItem(annualIncome, gender, sbjnbl, gjj, checked, true)
      const mnsdss = res1.yngrsdse - res2.yngrsdse
      const ybzsdss = mnsdss * (this.getMaxAge(gender) - age)

      console.log({
        res1,
        res2,
        mnsdss,
        ybzsdss
      })
      return {
        res1,
        res2,
        mnsdss,
        ybzsdss
      }
    }
  }
})()

const gender = [
  {
    name: '男',
    value: 'male'
  },
  {
    name: '女',
    value: 'female'
  }
]

export { config, calculator, gender, sbjnbl }
