import { http } from '../config/http'
import MockAdapter from 'axios-mock-adapter'
import { API } from '../config/api'
import { modules } from './factory/modules'
import { zhishu } from './factory/zhishu'
import { meta } from './factory/meta'
import xjb from './factory/xingjiabi'
import { str2obj } from '../utils'

const respModel = {
  to (result) {
    return {
      code: '200',
      msg: 'ok',
      result
    }
  }
}
export default {
  install () {
    const mock = new MockAdapter(http, {
      // delayResponse: 200
    })

    mock.onAny(API.modules).reply(200, respModel.to(modules))

    mock.onAny(API.zhishu).reply(200, respModel.to(zhishu))

    mock.onAny(API.meta).reply(200, respModel.to(meta))

    mock.onAny(API.queryXingjiabi).reply((config) => {
      return [200, respModel.to(xjb.mock(str2obj(config.data)))]
    })
  }
}
