import { createApp } from 'vue'
import App from './App.vue'
import Config from './config/base'
import mock from './mock/index'
import router from './router'

if (process.env.NODE_ENV !== 'production') {
  mock.install()
}
const app = createApp(App)
app.use(Config)
app.use(router)

app.mount('#app')
